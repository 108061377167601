/* GLOBAL VARIABLES */
body {
  max-width: 2200px;
  justify-items: center;
  min-width: 320px;
}

.container {
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;
  display: block;
}

.logo-header {
  /* display: none; */
}

.menu-icon {
  font-size: 2rem;
}

header nav a {
  font-family: 'Raleway', sans-serif;
  color: blue;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px;
}

header nav a.active {
  font-weight: 700;
  text-decoration: underline #ffc000;
  transition: all 0.3s ease .3s;
}

header nav img {
  height: 40vmin;
  pointer-events: none;
}

.hr-header {
  margin-top: 2rem;
  border: 1px solid black;
  max-width: 768px;
  width: 100%;
}

.mobileBreak {
  display: block;
}
.mobileView {
  display: none;
}
/* Footer */

footer a {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: 1.25rem;
  text-decoration: none;
  padding: 5px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
footer svg:hover,
footer svg:focus-visible,
footer svg:focus-within {
  color: #1a05f8;
  transform: translateY(-2px);
}
footer .logo-credit:hover,
footer .logo-credit:focus-visible,
footer .logo-credit:focus-within {
  color: #1a05f8;
  text-decoration:underline #ffc000;
  font-weight: 700;
}
.hr-footer {
  margin-bottom: 2rem;
  border: 1px solid black;
  max-width: 768px;
  width: 100%;
}

.logo-credit {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: .75rem;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #282c34;
}

.App-name {
  font-family: 'Raleway Dots', cursive;
  font-size: 3.5rem;
  font-weight: normal;
  margin: 0;
}

.App-link {
  font-family: 'Raleway', sans-serif;
  color: blue;
  text-decoration: none;
  font-size: 1rem;
}
.App-text {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: 1rem;
  padding-right: 15px;
}
.App-vl {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: 1rem;
}
.App-vl-footer {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: .75rem;
}
.Nav-menu {
  flex-direction: column;
}

/* Home Page */

.Home-copy {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: 1rem;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .logo-header {
    display: block;
  }
  .App-name {
    font-size: 4.5rem;
  }
  .mobileBreak {
    display: none;
  }
  .mobileView {
    display: inline;
  }
}

/* BUTTONS  */

button {
  width: 140px;
  height: 45px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  color: #fff;
  background-color: #1a05f8;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }
button a {
  color: #fff;
}
button:hover,
button:focus-visible,
button:focus-within {
  background-color: #1a05f8;
  box-shadow: 0px 15px 20px rgba(26,5,248, 0.4);
  color: #fff;
  transform: translateY(-4px);
}
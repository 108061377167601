
.Database-copy {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 0 15px;
  }

  ul {
    text-align: start;
  }
.Database-copy a {
  text-decoration: none;
}
.Database-copy a:hover,
.Database-copy a:focus-visible,
.Database-copy a:focus-within {
  color: #1a05f8;
  text-decoration:underline #ffc000;
  font-weight: 700;
}

  .Database-copy li {
    list-style-type: none;
    padding: 5px;
  } 
.Database-copyH3 {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
}

.beta-db-btn {
  margin-top: 10px;
}

.Database-disclaimer {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: .75rem;
  padding: 0 15px;
  font-style: italic;
}
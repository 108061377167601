.About-header {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 2rem;
    font-style: italic;
    text-align: center;
  }
  
  .App-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
  }
  .About-h3 {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    padding: 0 15px;
    text-align: center;
  }
  .About-copy {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 0 15px;
  }
  .About-copy a {
    text-decoration: none;
  }
  .About-copy a:hover,
  .About-copy a:focus-visible,
  .About-copy a:focus-within {
    color: #1a05f8;
    text-decoration:underline #ffc000;
    font-weight: 700;
  }
  .About-copy li {
    list-style-type: none;
    padding: 5px;
  }
  .community-wrap {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 0 15px;
    text-align: center;
  }
  .community-wrap a {
    text-decoration: none;
  }
  .community-wrap a:hover,
  .community-wrap a:focus-visible,
  .community-wrap a:focus-within {
    color: #1a05f8;
    text-decoration:underline #ffc000;
    font-weight: 700;
  }
.census-container {
  /* padding: 15px; */
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;
  display: table;
}
.Census-copy {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 0 15px;
  }

  .Census-dates-wrap {
    margin: auto;
    border: 1px solid #282c34;
    padding: 10px;
    max-width: 580px;
  }
  .Census-copy-dates {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    max-width: 480px;
    margin: auto;
  }
  .Census-copy-start {
    font-style: italic;
  }
.Census-copy .minimum {
  text-decoration: underline;
}
  .Census-copy-btm {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 0 15px;
    margin-bottom: 25px;
  }
  .Census-copy-btm a {
    text-decoration: none;
  }
  .Census-copy-btm a:hover,
  .Census-copy-btm a:focus-visible,
  .Census-copy-btm a:focus-within {
    color: #1a05f8;
    text-decoration:underline #ffc000;
    font-weight: 700;
  }
  button {
    width: 140px;
    height: 45px;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    color: #fff;
    background-color: #1a05f8;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    }
  button a {
    color: #fff;
    text-decoration: none;
  }
  button:hover,
  button:focus-visible,
  button:focus-within {
    background-color: #1a05f8;
    box-shadow: 0px 15px 20px rgba(26,5,248, 0.4);
    color: #fff;
    transform: translateY(-4px);
  }
  .sd-body .sd-body__page {
    min-width: 320px;
}
  .sd-remaining-character-counter {
    position: initial;
  }

  .sd-root-modern--mobile .sd-body__navigation .sd-btn {
    padding-bottom: 2.5rem;
  }

  .sd-btn .sd-navigation__prev-btn {
    padding-bottom: 2.5rem;
  }

  .sd-body__navigation .sd-btn {
    padding-bottom: 2.5rem;
  }

/* IFRAME */

  iframe {
    height: 110vh !important;
    width: 320px;
    border: none;
  }

  @media (min-width: 480px) {
    iframe {
      width: 480px;
    }
  }

  
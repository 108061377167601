.volHeadline {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
}

.volHeadline a {
    text-decoration: none;
}

.volHeadline a:hover,
.volHeadline a:focus-visible,
.volHeadline a:focus-within {
    color: #1a05f8;
    text-decoration:underline #ffc000;
    font-weight: 700;
}
.volList {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    text-align: start;
}

p {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
}
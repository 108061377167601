.contact-copy {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
  font-size: 1rem;
  padding: 0 15px;
}
h3 {
  font-family: 'Raleway', sans-serif;
  color: #282c34;
}
/* FORM  */

label {
    font-family: 'Raleway', sans-serif;
    color: #282c34;
    font-size: 1rem;
    padding: 10px 0;
  }
  
  form {
    padding: 15px 0 15px 0;
  }
  
  input {
    width: 16.125rem;
    height: 1.75rem;
    border: 1px solid #1a05f8;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  button {
    width: 16.35rem;
  }
  
  .input-wrapper {
    padding: 15px 0;
  }
  
  textarea {
    width: 16rem;
    height: 5rem;
    border: 1px solid blue;
    border-radius: 4px;
    margin-bottom: 10px;
  }